import './styles/app.scss';
import { Home } from './app/pages/home/Home';

function App() {
  return (
    <Home />
  );
}

export default App;
