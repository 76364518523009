import React from 'react';
import logo from '../../../assets/pangealogo.png';
import './header.scss';

export const Header: React.FC = () => {
    return (
        <div className='flex absolute z-40 container-header'>
            <img src={logo} alt="Red Pangea Logo" />
            <div className='flex items-center gap-12'>
                <p>Inicio</p>
                <p>Nosotros</p>
                <p>Proyectos</p>
                <p>Servicios</p>
                <p>Contáctanos</p>
            </div>
        </div>
    );
};
