import React from 'react'
import { Button } from '../../components/buttons/Button'
import { Header } from '../../components/header/Header'
import Footer from '../footer/Footer'
import { Proyectos } from '../proyectos/Proyectos'
import { Servicios } from '../servicios/Servicios'
import './Home.scss'
export const Home = () => {
    return (
        <div>


            <section>
                <Header />
                <div className='background-image'>
                </div>
                <div className='gradient'>
                </div>

                <div className='container max-w-full'>
                    <div className='translate-y-1/2 absolute'>
                        <h2>Somos una <br /> organizacion sin<br />  ánimo de lucro</h2>
                        <p className='w-2/5 mb-4'>Trabajamos incansablemente para transformar a las comunidades más vulnerables, creando oportunidades que permitan satisfacer integralmente sus necesidades y de esta manera contribuir a mejores condiciones de vida. </p>
                        <Button label='Conocer más' style='primary' />
                        <Button label='Contáctanos' style='secondary' extraclassName='ml-4' />
                    </div>
                </div>
            </section>
            <Servicios />
            <Proyectos />
            <Footer />
        </div>
    )
}
