import { title } from 'process';
import React from 'react'
import './Post.scss'

interface CardProps {
    imageUrl?: string;
    title: string;
    text: string;
}
export const Post: React.FC<CardProps> = ({ imageUrl, title, text }) => {
    return (
        <div className='flex flex-col w-1/2  justify-around gap-y-4'>
            <div className='circle-icon'>
                <img src={imageUrl} alt="" />
            </div>
            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    )
}
