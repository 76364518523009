import React from 'react';
import Card from '../../components/card/Card';
import { Post } from '../../components/post/Post';
import './Servicios.scss';
import family from '../../../assets/family.svg';
import hand from '../../../assets/handshake.svg';
import person from '../../../assets/person.svg';
import leaf from '../../../assets/leaf.svg';

export const Servicios = () => {

    const actionLines = [
        {
            img: family,
            title: 'Fortalecimiento Comunitario',
            text: 'Entrega inmediata de suministros esenciales (alimentos, agua, refugio) a poblaciones afectadas por crisis y desastres naturales.'
        },
        {
            img: leaf,
            title: 'Apoyo A La Agricultura Y Cuidado Ambiental',
            text: 'Implementación de proyectos sostenibles para mejorar la productividad agrícola, conservar los recursos naturales y promover prácticas respetuosas con el medio ambiente.'
        },
        {
            img: hand,
            title: 'Asistencia Humanitaria',
            text: 'Entrega inmediata de suministros esenciales (alimentos, agua, refugio) a poblaciones afectadas por crisis y desastres naturales.'
        },
        {
            img: person,
            title: 'Desarrollo Social',
            text: 'Diseño y ejecución de programas que fomenten la inclusión social, el empoderamiento comunitario y el acceso a servicios básicos como educación, salud y vivienda.'
        }
    ];

    return (
        <div className='container-services gap-36'>
            <div className='container-allies'>
                <img className='iconos' src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Logo_Ministerio_de_Agricultura_%282022-2026%29.png/640px-Logo_Ministerio_de_Agricultura_%282022-2026%29.png' alt='Ministerio de Agricultura' />
                <img className='iconos' src='https://www.greatplacetowork.com.co/images/CompaniesCertification/Fotos/Fiduagraria/2024/158da94e-9e7e-44b4-a229-5d1243059d8d.png' alt='Fiduagraria' />
                <img className='iconos' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxTfiI36q_1uvTt3VVKU-_YQEq5ZbRYJv-uA' alt='Logo 1' />
                <img className='iconos' src='https://www.minagricultura.gov.co/noticias/PublishingImages/Paginas/Forms/AllItems/Logo%20PAAP%201.png' alt='PAAP' />
                <img className='iconos' src='https://static.wixstatic.com/media/4604b7_7ace9af1912b4efea1ad0810a6c99216~mv2.png/v1/fill/w_196,h_196,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4604b7_7ace9af1912b4efea1ad0810a6c99216~mv2.png' alt='Logo 2' />
            </div>
            <div className='flex px-16 gap-8'>
                <div className='flex flex-col justify-evenly'>
                    <h2 className='text-7xl text-start title'>Sobre lo que <b>hacemos</b> </h2>
                    <p>Trabajamos incansablemente para transformar a las comunidades más vulnerables, creando oportunidades que permitan satisfacer integralmente sus necesidades y de esta manera contribuir a mejores condiciones de vida. Para lograr este objetivo, participamos activamente, nos concentramos en cuatro líneas de acción:</p>
                </div>
                <div className='flex flex-wrap gap-y-9'>
                    {actionLines.map((action, index) => (
                        <Post key={index} imageUrl={action.img} title={action.title} text={action.text} />
                    ))}
                </div>
            </div>
        </div>
    );
};
