import React from 'react';
import './Footer.scss';
import logo from "../../../assets/redpangealogo2.png"
import { Button } from '../../components/buttons/Button';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="call-to-action">
                <h1>Únete a nosotros</h1>
                <p>Únete a nosotros para una vida mejor y un futuro hermoso</p>
                <Button label='Unirse' style='secondary' />

            </div>
            <div className="footer-main">
                <div className="footer-logo">
                    <img src='https://cdn.discordapp.com/attachments/1040474892321181797/1256812622267809924/pangealogo2.png?ex=66822177&is=6680cff7&hm=929a0489a9342109ee9b85306d9f12332791222ede50a84b89b0403565596983' alt="HOPE" />
                    <p>© 2024 Red pangea. Todos los derechos reservados.</p>
                </div>
                <div className="footer-links">
                    <div className="footer-column">
                        <h3>Descubre Cómo Estamos Transformando Vidas En Redes Sociales</h3>
                        <div className="social-icons">
                            <a id='whatsapp' href="https://wa.me/573147711678?text=Hola,%20me%20gustaría%20obtener%20más%20información%20sobre%20sus%20servicios.">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png" alt="Whatsapp Logo" />
                            </a>
                            <a href="#">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1000px-Instagram_logo_2022.svg.png" alt="Instagram Logo" /><i className="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png" alt="facebook logo" /><i className="fab fa-google"></i>
                            </a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
