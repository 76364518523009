import React, { useState } from 'react';
import './Proyectos.scss';

export const Proyectos = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const proyectos = [
        {
            title: "ENTREGA DE 35.771 KITS DE ALIMENTOS CON DESTINO A POBLACIÓN VULNERABLE MIGRANTE",
            description: "Corppaz, en alianza con la Gerencia para la Frontera Nortesantandereana y la Unidad Nacional para la Gestión del Riesgo de Desastres (UNGRD), lideró una iniciativa de asistencia humanitaria de emergencia para atender las necesidades de la población migrante vulnerable en Norte de Santander durante la vigencia 2020.",
            img: "https://corppaz.org/static/media/entrega_productos.6d1fdc329aa6d88e58ba.png"
        },
        {
            title: "FORTALECIMIENTO DE MEDIOS DE VIDA COLECTIVOS EN LA ZONA RURAL DE SAN JOSÉ DE CÚCUTA PARA VÍCTIMAS DEL CONFLICTO ARMADO",
            description: "Transformando la zona rural de Cúcuta en territorio de Paz y Emprendimiento a través de la puesta en marcha de Unidades Productivas en Líneas de Ganadería, Cacao, Mango Tommy, Caña de Azúcar y Apicultura",
            img: "https://corppaz.org/static/media/muchas_personas.162adcc9c22cc8f850be.png"
        },
        {
            title: "MEJORAMIENTO DE LA PRODUCCIÓN AGROPECUARIA EN CORREGIMIENTOS DE CÚCUTA",
            description: "Este proyecto impulsó la producción cárnica y láctea en los corregimientos de San Faustino y Guaramito mediante la creación de una asociación de ganaderos, capacitación en buenas prácticas y mejoramiento genético, e implementación de tecnologías como inseminación artificial y bancos de proteínas.",
            img: "https://corppaz.org/static/media/hombre.7eff2138bd416426c6ce.png"
        }
    ];

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? proyectos.length - 1 : prevIndex - 1));
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % proyectos.length);
    };

    return (
        <div className='container-services2'>
            <h2>Nuestros Proyectos</h2>
            <div className='carousel'>
                <button className='prev' onClick={prevSlide}>‹</button>
                <div className='carousel-content'>
                    {proyectos.map((proyecto, index) => (
                        <div
                            key={index}
                            className={`slide ${index === currentIndex ? 'active' : ''}`}
                        >
                            <div className='proyectos-container'>
                                <div className='image-section'>
                                    <img src={proyecto.img} alt={proyecto.title} />
                                </div>
                                <div className='content-section'>
                                    <h1>{proyecto.title}</h1>
                                    <p>{proyecto.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button className='next' onClick={nextSlide}>›</button>
            </div>
        </div>
    );
};

export default Proyectos;
