import React, { FC } from 'react';
import './button.scss';

interface ButtonProps {
    style: string;
    extraclassName?: string;
    label: string;
    onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({ style, label, extraclassName, onClick }) => {
    return (
        <button className={`button ${style} ${extraclassName}`} onClick={onClick}>
            {label}
        </button>
    );
};

